import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Redux/store';
import { setAuth } from '../Redux/authSlice';
import { Link } from 'react-router-dom';

interface Employee {
  id: number;
  name: string;
  phone_number: string;
  IdNumber: string;
  date_reg: string;
  role: string;
}

export default function EmployeeList() {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth.value);

  useEffect(() => {
    // Fetch employees from the backend API
    axios.get<Employee[]>('https://api.darululuum.com/employee/employees/')
      .then(response => {
        setEmployees(response.data);
      })
      .catch(error => {
        console.error('Error fetching employees:', error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
        try {
            // Retrieve token from cookie
            const token = getCookie('token');
            if (!token) {
                dispatch(setAuth(false));
                return;
            }

            // Set token in Axios default headers
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            // Fetch user data
            const { data } = await axios.get('https://api.darululuum.com/api/user');
            // Assuming data contains first_name and last_name
            const { first_name, last_name } = data;
            // Set authentication status in Redux store
            dispatch(setAuth(true));
        } catch (e) {
            dispatch(setAuth(false));
        }
    };

    fetchData();
}, [dispatch]);

// Function to get cookie by name
const getCookie = (name: string) => {
    const cookieName = name + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return '';
};


  return (
    <>
    
    <div className="container mt-5">
    {auth ? (
        <div className="table-responsive custom-table" style={{ marginLeft: "180px" }}>
            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                <thead className="table-dark">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">ID_Number</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Date</th>
                        <th scope="col">Role </th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {employees.map((employee, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{employee.name}</td>
                            <td>{employee.IdNumber}</td>
                            <td>{employee.phone_number}</td>
                            <td>{employee.date_reg}</td>
                            <td>{employee.role}</td>
                            <td>
                                <Link className="btn btn-outline-primary btn-sm" to={`/employee/${employee.id}/`}>
                                    Details
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    ) : (
        <div>
            {/* <h3>You are not authenticated</h3> */}
        </div>
    )}
</div>
<style>
    {`
        @media (max-width: 980px) {
            .custom-table {
                margin-left: 0 !important;
            }
        }
    `}
</style>
    </>
  );
}
