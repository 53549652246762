import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../Redux/store';
import { setAuth } from '../Redux/authSlice';
import { Navigate, useNavigate } from 'react-router-dom';

const AddFees: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    indexnumber: '',
    month: '',
    amount_paid: '',
    date_paid: '',
    enrolled_class: '',
    collected_by: '', // New field for collected_by
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCollectedByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, collected_by: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post('https://api.darululuum.com/fees/fees/', formData);
      setIsSubmitted(true);
    } catch (error: any) {
      console.error('Error adding fee:', error);
      if (error.response && error.response.status === 404) {
        alert('Student IndexNumber not found. Please enter a valid national ID.');
      } else {
        alert('Error adding fee. Please try again.');
      }
    }
  };

  if (isSubmitted) {
    navigate('/fees');
  }

  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
  const auth = useSelector((state: RootState) => state.auth.value);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const { data } = await axios.get('https://api.darululuum.com/api/user');
        setUserInfo({ firstName: data.first_name, lastName: data.last_name });
        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false));
      }
    };

    fetchData();
  }, [dispatch]);

  const getCookie = (name: string) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-1">
            {auth ? (
              <>
                {/* <Sidebar userName={`${userInfo.firstName} ${userInfo.lastName}`} /> */}
              </>
            ) : (
              <div>
                <h3>You are not authenticated</h3>
                <Navigate to="/" />
              </div>
            )}
          </div>
          <div className="col-md-9">
            <div className="d-flex justify-content-center align-items-center vh-100">
              <div className="card p-4" style={{ width: '70%', borderRadius: '10px', backgroundColor: "white" }}>
                <h2 className="text-center mb-4">Add Fee</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Student Index Number:</label>
                    <input type="text" className="form-control" style={{ width: '100%', height: '35px' }} name="indexnumber" value={formData.indexnumber} onChange={handleInputChange} />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Month:</label>
                    <select className="form-control" style={{ width: '100%', height: '35px' }} name="month" value={formData.month} onChange={handleSelectChange}>
                      <option value="">Select Month</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Amount Paid:</label>
                    <input type="text" className="form-control" style={{ width: '100%', height: '35px' }} name="amount_paid" value={formData.amount_paid} onChange={handleInputChange} />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Date Paid:</label>
                    <input type="date" className="form-control" style={{ width: '100%', height: '35px' }} name="date_paid" value={formData.date_paid} onChange={handleInputChange} />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Enrolled Section:</label>
                    <select className="form-control" style={{ width: '100%', height: '35px' }} name="enrolled_class" value={formData.enrolled_class} onChange={handleSelectChange}>
                      <option value="Choose">Choose Section</option>
                      <option value="Boarding">Boarding</option>
                      <option value="Machadka Luqada Carabiiga">Machadka Luqada Carabiiga</option>
                      <option value="Machadka Tahilka">Machadka Tahilka</option>
                      <option value="Al madarasa">Al madarasa</option>
                      <option value="Machadka Qiira’adka">Machadka Qiira’adka</option>
                      <option value="Machdul Mutuun Al Madhani">Machdul Mutuun Al Madhani</option>
                      <option value="Taxfiid Quraan">Taxfiid Quraan</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Collected By:</label>
                    <input type="text" className="form-control" style={{ width: '100%', height: '35px' }} name="collected_by" value={formData.collected_by} onChange={handleCollectedByChange} />
                  </div>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFees;
