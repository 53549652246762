import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Redux/store';
import { setAuth } from '../Redux/authSlice';

interface Student {
    id: number;
    name: string;
    birth_certificate: string;
    date_of_registration: string; // Keep as string for input compatibility
    student_type: string;
    parent_phone_number: string;
    index_number: string;
    Class: string;
    section: string;
}

const UpdateStudent: React.FC = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth.value);
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
    const [name, setName] = useState('');
    const [birthCertificate, setBirthCertificate] = useState('');
    const [studentType, setStudentType] = useState('');
    const [parentPhoneNumber, setParentPhoneNumber] = useState('');
    const [Class, setClass] = useState('');
    const [section, setSection] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        loadStudent();
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                const { data } = await axios.get('https://api.darululuum.com/api/user');
                setUserInfo({ firstName: data.first_name, lastName: data.last_name });

                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    const loadStudent = async () => {
        try {
            const { data } = await axios.get<Student>(`https://api.darululuum.com/students/students/${id}`);
            setName(data.name);
            setBirthCertificate(data.birth_certificate);
            setStudentType(data.student_type);
            setParentPhoneNumber(data.parent_phone_number);
            setClass(data.Class);
            setSection(data.section);
        } catch (error) {
            console.error('Error loading student:', error);
        }
    };

    const updateSingleStudent = async () => {
        try {
            setLoading(true);
            const formField = new FormData();
            formField.append('name', name);
            formField.append('birth_certificate', birthCertificate);
            formField.append('student_type', studentType);
            formField.append('parent_phone_number', parentPhoneNumber);
            formField.append('Class', Class);
            formField.append('section', section);

            const response = await axios.put(`https://api.darululuum.com/students/students/${id}/`, formField);
            if (response.status === 200) {
                alert('Student updated successfully');
                navigate('/students');
            } else {
                setError('Failed to update student. Please try again.');
            }
        } catch (error) {
            console.error('Error updating student:', error);
            setError('Failed to update student. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const getCookie = (name: string) => {
        const cookieName = name + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card " style={{backgroundColor:"white"}}>
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">Update Student</h2>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <form onSubmit={(e) => { e.preventDefault(); }}>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="birth_certificate">Birth Certificate</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="birth_certificate"
                                        name="birth_certificate"
                                        value={birthCertificate}
                                        onChange={(e) => setBirthCertificate(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="student_type">Student Type</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="student_type"
                                        name="student_type"
                                        value={studentType}
                                        onChange={(e) => setStudentType(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="parent_phone_number">Parent Phone Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="parent_phone_number"
                                        name="parent_phone_number"
                                        value={parentPhoneNumber}
                                        onChange={(e) => setParentPhoneNumber(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Class">Class</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Class"
                                        name="Class"
                                        value={Class}
                                        onChange={(e) => setClass(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="section">Section</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="section"
                                        name="section"
                                        value={section}
                                        onChange={(e) => setSection(e.target.value)}
                                        disabled={loading}
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    onClick={updateSingleStudent}
                                    disabled={loading}
                                >
                                    {loading ? 'Updating...' : 'Update Student'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateStudent;
