import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Components/Login';
import { Reset } from './Components/Resest';
import { Forgot } from './Components/Forgot';
import Register from './Components/Register';
import Dasboard from './Components/Dasboard';
import AddStudents from './Components/students/AddStudents';
import DetailStudent from './Components/students/DetailStudents';
import UpdateStudent from './Components/students/UpdateStudents';
import ShowStudents from './Components/students/StudentList';
import Sidebar from './Components/Sidebar';
import { Nav } from './Components/Nav';
import UserComponent from './Components/UserComponent';
import AddTeachers from './Components/Teachers/AddTeachers';
import ShowTeachers from './Components/Teachers/ShowTeachers';
import DetailTeachers from './Components/Teachers/DetailTeacher';
import UpdateTeachers from './Components/Teachers/UpdateTeacher';
import EmployeeList from './Components/employees/EmployeeList';
import DetailsEmloyee from './Components/employees/DetailsEmloyee';
import UpdateEmloyee from './Components/employees/UpdateEmloyee';
import AddEmployee from './Components/employees/AddEmployee';
import AddFees from './Components/Fees/AddFees';
import ExpenseList from './Components/Exmpenses/ExpenseList';
import ExpenseForm from './Components/Exmpenses/ExpenseForm';
import IncomeExpenseSummary from './Components/Income/IncomeExpenseSummary';
import ShowFees from './Components/Fees/ShowFees';
import DetailFees from './Components/Fees/DetailFees';

function App() {
    return (
        <BrowserRouter>
        <Nav/>
        <Sidebar/>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/reset/:token" element={<Reset/>}/>
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/register" element={<Register />} />
                <Route path="/users" element={<UserComponent />} />
                {/* {authentications} */}
                <Route path="/dashboard" element={<Dasboard/>} />
                <Route path="/students" element={<ShowStudents />} />
                <Route path="/add-students" element={<AddStudents />} />
                <Route path='/student/:id' element={<DetailStudent />} />
                <Route path='/:id/update-student' element={<UpdateStudent />} />
                {/* Teachers */}
                <Route path="/add-teachers" element={<AddTeachers />} />
                <Route path="/teachers" element={<ShowTeachers />} />
                <Route path='/teacher/:id' element={<DetailTeachers />} />
                <Route path='/:id/update-Teacher' element={<UpdateTeachers />} />
                {/* Employees */}
                <Route path="/employees" element={<EmployeeList />} />
                <Route path='/employee/:id' element={<DetailsEmloyee />} />
                <Route path='/:id/update-employee' element={<UpdateEmloyee />} />
                <Route path="/add-employee" element={<AddEmployee />} />
                {/* Fees */}
                <Route path='/:id/' element={<DetailFees/>} />
                <Route path="/add-fees" element={<AddFees/>} />
                <Route path="/expenses" element={<ExpenseForm/>} />
                <Route path="/income" element={<IncomeExpenseSummary/>} />
                <Route path="/ExpenseList" element={<ExpenseList/>} />
                <Route path="/fees" element={<ShowFees/>} />
                <Route path="/income" element={<IncomeExpenseSummary/>} />



                {/* update-employee */}



            </Routes>
        </BrowserRouter>
    );
}

export default App;
