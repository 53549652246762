import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../Redux/authSlice';
import { RootState } from '../Redux/store';

export default function AddStudents() {
    const [name, setName] = useState('');
    const [birth_certificate, setBirthCertificate] = useState('');
    const [date_of_registration, setDateOfRegistration] = useState('');
    const [student_type, setStudentType] = useState('');
    const [parent_phone_number, setParentPhoneNumber] = useState('');
    const [Class, setClass] = useState('');
    const [section, setSection] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
    const auth = useSelector((state: RootState) => state.auth.value);
    const navigate = useNavigate();

    const addNewStudent = async () => {
        if (!name || !birth_certificate || !date_of_registration || !student_type || !parent_phone_number || !Class || !section) {
            setErrorMessage('Please fill in all the required fields.');
            return;
        }

        try {
            const formField = new FormData();
            formField.append('name', name);
            formField.append('birth_certificate', birth_certificate);
            formField.append('date_of_registration', date_of_registration);
            formField.append('student_type', student_type);
            formField.append('parent_phone_number', parent_phone_number);
            formField.append('Class', Class);
            formField.append('section', section);

            await axios.post('https://api.darululuum.com/students/students/', formField);
            alert('Student added successfully');
            navigate('/students');
        } catch (error) {
            console.error('Error adding student:', error);
            setErrorMessage('Failed to add student. Please try again.');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const { data } = await axios.get('https://api.darululuum.com/api/user');
                setUserInfo({ firstName: data.first_name, lastName: data.last_name });
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    // Function to handle changes in student type
    const handleStudentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const type = e.target.value;
        setStudentType(type);
        // Set section based on student type
        if (type === 'Day') {
            setSection('');
        } else if (type === 'Boarding') {
            setSection('');
        }
    };

    return (
        <>
            <div className="container text-center mt-5">
                {auth ? (
                    <div className="shadow p-5 mx-auto" style={{ maxWidth: '600px', width: '100%' }}>
                        <h3 className="mb-4">Add a new Student</h3>
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        <form>
                            <div className="form-group mb-2">
                                <input
                                    type="text"
                                    className="form-control p-2"
                                    placeholder="Enter student's Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mb-2">
                                <input
                                    type="text"
                                    className="form-control p-2"
                                    placeholder="Enter Birth Certificate or Date of birth"
                                    value={birth_certificate}
                                    onChange={(e) => setBirthCertificate(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mb-2">
                                <input
                                    type="date"
                                    className="form-control p-2"
                                    value={date_of_registration}
                                    onChange={(e) => setDateOfRegistration(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mb-2">
                                <input
                                    type="text"
                                    className="form-control p-2"
                                    placeholder="Enter Parent Phone Number"
                                    value={parent_phone_number}
                                    onChange={(e) => setParentPhoneNumber(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mb-2">
                                <select
                                    className="form-control p-2"
                                    value={student_type}
                                    onChange={handleStudentTypeChange}
                                    required
                                >
                                    <option value="" disabled>Select Student Type</option>
                                    <option value="Boarding">Boarding</option>
                                    <option value="Day">Day</option>
                                </select>
                            </div>
                            {student_type && student_type === 'Day' && (
                                <div className="form-group mb-2">
                                    <select
                                        className="form-control p-2"
                                        value={section}
                                        onChange={(e) => setSection(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled>Select Section</option>
                                        <option value="Machadka Luqada Carabiiga">Machadka Luqada Carabiiga</option>
                                        <option value="Machadka Tahilka">Machadka Tahilka</option>
                                        <option value="Al madarasa">Al Madarasa</option>
                                        <option value="Machadka Qiira’adka">Machadka Qiira’adka</option>
                                        <option value="Machdul Mutuun Al Madhani">Machdul Mutuun Al Madhani</option>
                                        <option value="Taxfiid Quraan">Taxfiid Quraan</option>

                                    </select>
                                </div>
                            )}
                            {student_type && student_type === 'Boarding' && (
                                <div className="form-group mb-2">
                                    <select
                                        className="form-control p-2"
                                        value={section}
                                        onChange={(e) => setSection(e.target.value)}
                                        required
                                    >
                                        <option value="" disabled>Select Section</option>
                                        <option value="Boarding">Boarding</option>
                                        <option value="Machadka Luqada Carabiga">Machadka Luqada Carabiga</option>
                                        <option value="Machadka Tahilka">Machadka Tahilka</option>
                                        <option value="Al madarasa">Al Madarasa</option>
                                        <option value="Machadka Qiira’adka">Machadka Qiira’adka</option>
                                        <option value="Xifdul Mutuun Al Madani">Xifdul Mutuun Al Madani</option>
                                    </select>
                                </div>
                            )}
                            <div className="form-group mb-2">
                                <input
                                    type="text"
                                    className="form-control p-2"
                                    placeholder="Enter Class Name"
                                    value={Class}
                                    onChange={(e) => setClass(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="button" className="btn btn-primary btn-block" onClick={addNewStudent}>
                                Add Student
                            </button>
                        </form>
                    </div>
                ) : (
                    <div>
                        <h3>You are not authenticated</h3>
                    </div>
                )}
            </div>
        </>
    );
}
