import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RootState } from '../Redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../Redux/authSlice';

const IncomeExpenseSummary: React.FC = () => {
    const [incomeData, setIncomeData] = useState<{ [key: string]: { total_income: string; total_expenses: string; net_income: string } }>({});
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', role: '' }); // Added role in userInfo state
    const auth = useSelector((state: RootState) => state.auth.value);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.darululuum.com/fees/income/');
                setIncomeData(response.data.income_and_expenses_by_month);
            } catch (error) {
                console.error('Error fetching income data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Retrieve token from cookie
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                // Set token in Axios default headers
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                // Fetch user data
                const { data } = await axios.get('https://api.darululuum.com/api/user'); // Fetching user information including role
                setUserInfo({ firstName: data.first_name, lastName: data.last_name, role: data.role }); // Setting user information including role

                // Set authentication status in Redux store
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    // Function to get cookie by name
    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    return (
        <>
        <div className="container mt-4">
            {auth && userInfo.role === 'admin' || userInfo.role === 'super_admin' ? ( // Check if user is authenticated and has admin role
                <div>
                    <h3 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>Income and Expense Summary</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover income-expense-table" style={{ width: '730px', margin: '10px auto' }}>
                            <thead>
                                <tr>
                                    <th>Month</th>
                                    <th>Total Income</th>
                                    <th>Total Expenses</th>
                                    <th>Net Income</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(incomeData).map((month, index) => (
                                    <tr key={month} className={index % 2 === 0 ? 'even-row' : ''}>
                                        <td>{month}</td>
                                        <td>ksh {incomeData[month].total_income}</td>
                                        <td>ksh {incomeData[month].total_expenses}</td>
                                        <td>ksh {incomeData[month].net_income}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div>
                    <p style={{textAlign:"center"}}>you are not authorised to view This page</p>
                </div>
            )}
        </div>
        </>
    );
};

export default IncomeExpenseSummary;
