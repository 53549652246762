import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Nav } from '../Nav';

interface Teacher {
    id: number;
    name: string;
    email: string;
    Class: string;
    IdNumber: string;
    phoneNumber: string;
}
const UpdateTeachers: React.FC = () => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [Class, setClass] = useState<string>('');
    const [IdNumber, setIdNumber] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        loadStudent();
    }, [id]);

    const loadStudent = async () => {
        try {
            const result = await axios.get<Teacher>(`https://api.darululuum.com/teachers/teachers/${id}`);
            const { data } = result;
            setName(data.name);
            setEmail(data.email);
            setClass(data.Class);
            setIdNumber(data.IdNumber);
            setPhoneNumber(data.phoneNumber);
        } catch (error) {
            console.error('Error loading teacher:', error);
        }
    };

const updateSingleTeacher = async () => {
    try {
        const formField = new FormData();
        formField.append('name', name);
        formField.append('email', email);
        formField.append('Class', Class);
        formField.append('IdNumber', IdNumber);
        formField.append('phoneNumber', phoneNumber);

        const response = await axios.put(`https://api.darululuum.com/teachers/teachers/${id}/`, formField);
        alert('Teachers updated');
        window.location.href = '/teachers';
    } catch (error) {
        console.error('Error updating Teachers:', error);
    }
};


    return (
        <>
        <div className="container">
            <div className="w-75 mx-auto shadow p-5">
                <h2 className="text-center mb-4">Update A Teacher</h2>
               
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter National ID"
                        value={IdNumber}
                        onChange={(e) => setIdNumber(e.target.value)}
                    />
                </div>
                <button onClick={updateSingleTeacher} className="btn btn-primary btn-block">Update Teacher</button>
            </div>
        </div>
        </>
    );
};

export default UpdateTeachers;
