import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../Redux/store';
import { setAuth } from '../Redux/authSlice';
import '../../App';

interface Student {
  id: number;
  name: string;
  birth_certificate: string;
  date_of_registration: string;
  student_type: string;
  parent_phone_number: string;
  Class: string;
  section: string;
  index_number: string;
}

export default function ShowStudents(): JSX.Element {
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedSection, setSelectedSection] = useState<string | null>('');
  const [sections, setSections] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const pageSize = 15; // Number of students per page

  const getStudents = async (): Promise<void> => {
    try {
      let url = 'https://api.darululuum.com/students/students/';
      const params: { [key: string]: string | number } = {
        page: currentPage,
        page_size: pageSize,
      };
      
      if (selectedSection) {
        params['section'] = selectedSection;
      }

      if (startDate && endDate) {
        params['start_date'] = startDate;
        params['end_date'] = endDate;
      }

      if (searchTerm) {
        params['name'] = searchTerm;
      }

      const response = await axios.get<{ results: Student[]; count: number }>(url, { params });
      const studentsData = response.data.results;
      setStudents(studentsData);

      // Extract unique sections from the fetched students
      const uniqueSections = Array.from(new Set(studentsData.map(student => student.section)));
      setSections(uniqueSections);

      setTotalPages(Math.ceil(response.data.count / pageSize));
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  useEffect(() => {
    getStudents();
  }, [selectedSection, currentPage, startDate, endDate, searchTerm]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
  const auth = useSelector((state: RootState) => state.auth.value);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve token from cookie
        const token = getCookie('token');
        if (!token) {
          dispatch(setAuth(false));
          return;
        }

        // Set token in Axios default headers
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // Fetch user data
        const { data } = await axios.get('https://api.darululuum.com/api/user');
        setUserInfo({ firstName: data.first_name, lastName: data.last_name });
        // Set authentication status in Redux store
        dispatch(setAuth(true));
      } catch (e) {
        dispatch(setAuth(false));
      }
    };

    fetchData();
  }, [dispatch]);

  // Function to get cookie by name
  const getCookie = (name: string) => {
    const cookieName = name + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return '';
  };

  return (
    <>
      <div className="container mt-4 table-container">
        {auth ? (
          <div className="row">
            <div style={{ position: 'relative' }}>
              <button className="btn btn-primary mt-3 mb-3" style={{ right: '10px', bottom: '-30px', position: 'absolute' }}>
                <Link to="/add-students" className="navLink" style={{ color: 'white', textDecoration: 'none' }}>
                  Add Student
                </Link>
              </button>
            </div>
            <div className="col-md-3">{/* Add Sidebar here if needed */}</div>
            <div className="select_container" style={{ margin: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <select value={selectedSection || ''} onChange={(e) => setSelectedSection(e.target.value)} style={{ marginTop: '-20px', width: '300px' }}>
                <option value="">All Sections</option>
                {sections.map((sec) => (
                  <option key={sec} value={sec}>
                    {sec}
                  </option>
                ))}
              </select>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <label htmlFor="fromDate" style={{ marginRight: '10px' }}>
                  From:
                </label>
                <input type="date" id="fromDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} style={{ marginRight: '10px', width: '280px' }} />
                <label htmlFor="toDate" style={{ marginRight: '10px' }}>
                  To:
                </label>
                <input type="date" id="toDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} style={{ marginRight: '10px', width: '230px' }} />
                <div className="search-container input-group" style={{ marginTop: '10px' }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-danger" type="button">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Table */}
            <div className="table-responsive medium">
              <table className="table table-striped table-md">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '15%' }}>
                      Name
                    </th>
                    <th scope="col" style={{ width: '10%' }}>
                      Birth Certificate
                    </th>
                    <th scope="col" style={{ width: '15%' }}>
                      Date of Registration
                    </th>
                    <th scope="col" style={{ width: '5%' }}>
                      Student Type
                    </th>
                    <th scope="col" style={{ width: '5%' }}>
                      Parent Phone Number
                    </th>
                    <th scope="col" style={{ width: '15%' }}>
                      Index Number
                    </th>
                    <th scope="col" style={{ width: '10%' }}>
                      Section Name
                    </th>
                    <th scope="col" style={{ width: '5%' }}>
                      Class Name
                    </th>
                    <th scope="col" style={{ width: '5%' }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                      <td>{student.name}</td>
                      <td>{student.birth_certificate}</td>
                      <td>{student.date_of_registration}</td>
                      <td>{student.student_type}</td>
                      <td>{student.parent_phone_number}</td>
                      <td>{student.index_number}</td>
                      <td>{student.section}</td>
                      <td>{student.Class}</td>
                      <td>
                        <Link className="btn btn-outline-primary btn-sm" to={`/student/${student.id}`}>
                          Details
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination buttons */}
            <div className="container-pagination" style={{ position: 'relative' }}>
              <div className="pagination" style={{ textAlign: 'right', marginTop: '4px', position: 'absolute', right: '-10px', bottom: '' }}>
                <button onClick={handlePreviousPage} className="btn btn-primary mr-2" disabled={currentPage === 1}>
                  Previous
                </button>
                <span className="mr-2">
                  {currentPage} / {totalPages}
                </span>
                <button onClick={handleNextPage} className="btn btn-primary" disabled={currentPage === totalPages}>
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h3>You are not authenticated</h3>
          </div>
        )}
      </div>
    </>
  );
}
