import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../Redux/store';
import { setAuth } from '../Redux/authSlice';
import Sidebar from '../Sidebar';
import { Nav } from '../Nav';

interface Teacher {
    id: number;
    name: string;
    email: string;
    Class: string;
    IdNumber: string;
    phoneNumber: string;
    date_reg: string;
}

export default function DetailTeachers(){
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
    const auth = useSelector((state: RootState) => state.auth.value);
    const [userRole, setUserRole] = useState('');

    const [teachers, setTeachers] = useState<Teacher>({} as Teacher);
    const { id } = useParams();
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Retrieve token from cookie
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                // Set token in Axios default headers
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                // Fetch user data
                const { data } = await axios.get('https://api.darululuum.com/api/user');
                setUserInfo({ firstName: data.first_name, lastName: data.last_name });
                setUserRole(data.role);

                // Set authentication status in Redux store
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    // Function to get cookie by name
    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    }


    useEffect(() => {
        getSingleTeacher();
    }, []);
    const getSingleTeacher = async () => {
        try {
            const { data } = await axios.get<Teacher>(`https://api.darululuum.com/teachers/teachers/${id}/`);
            setTeachers(data);
        } catch (error) {
            console.error('Error fetching Teachers:', error);
        }
    }
    const handleDelete = async () => {
        if (userRole === 'admin') {
        if (window.confirm("Are you sure you want to delete this teacher?")){
            try {
                await axios.delete(`https://api.darululuum.com/teachers/teachers/${id}/`);
                window.location.href = '/teachers';
                alert(`You have deleted ${teachers.name}`)
            } catch (error) {
                console.error('Error deleting product:', error);
            }
        }
    } else {
        alert("You do not have permission to delete this student.");
    }
    }

    return (
        <>

        <div className="container mt-5">
            {auth ? (
                <div>
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card" style={{backgroundColor:"white"}}>
                                <div className="card-body text-center">
                                    <h2 className="card-title">Teachers Detail's</h2>
                                    <hr />
                                    <p className="card-text"><strong>ID:</strong> {teachers.id}</p>
                                    <p className="card-text"><strong>Name:</strong> {teachers.name}</p>
                                    <p className="card-text"><strong>Email:</strong> {teachers.email}</p>
                                    <p className="card-text"><strong>National ID:</strong> {teachers.IdNumber}</p>
                                    <p className="card-text"><strong>Phone Number:</strong> {teachers.phoneNumber}</p>
                                    <p className="card-text"><strong>Date Registered:</strong> {teachers.date_reg}</p>
                                    <div className="d-flex justify-content-center">
                                        <Link className="btn btn-outline-primary mr-2" to={`/${id}/update-Teacher`}>Update</Link>
                                        {userRole === 'admin' && <button className="btn btn-danger" onClick={handleDelete}>Delete</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {/* <h3>You are not authenticated</h3> */}
                </div>
            )}
        </div>
        </>
    );
}