import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../Redux/store';
import { setAuth } from '../Redux/authSlice';

export default function AddEmployee() {
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth.value);
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
    const [name, setName] = useState('');
    const [IDNumber, setIdNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [role, setRole] = useState('');
    const [date_reg, setDate_reg] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const roles = ["Boarding Master", "Chef", "Shopkeeper"];

    const addNewEmployee = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!name || !role || !IDNumber || !date_reg || !phoneNumber) {
            setErrorMessage('Please fill in all the required fields.');
            return;
        }

        try {
            const formField = {
                name,
                role,
                IdNumber: IDNumber,
                phone_number: phoneNumber,
                date_reg
            };

            await axios.post('https://api.darululuum.com/employee/employees/', formField);
            alert('Employee added successfully');
            // Redirect to the list of employees
            window.location.href = '/employees';
        } catch (error) {
            console.error('Error adding employee:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                const { data } = await axios.get('https://api.darululuum.com/api/user');
                setUserInfo({ firstName: data.first_name, lastName: data.last_name });
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    }

    return (
        <div className="container text-center">
            <div className="mt-5">
                {auth ? (
                    <div className="w-75 mx-auto shadow p-5">
                        <h2 className="mb-4">Add An Employee</h2>
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        <form onSubmit={addNewEmployee}>
                            <div className="form-group mb-3">
                                <input type="text" className="form-control" placeholder="Enter Employee's Name" value={name} onChange={(e) => setName(e.target.value)} required />
                            </div>
                            <div className="form-group mb-3">
                                <select className="form-control" value={role} onChange={(e) => setRole(e.target.value)} required>
                                    <option value="">Select Role</option>
                                    {roles.map((roleOption, index) => (
                                        <option key={index} value={roleOption}>{roleOption}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <input type="text" className="form-control" placeholder="Enter Employee's National ID" value={IDNumber} onChange={(e) => setIdNumber(e.target.value)} required />
                            </div>
                            <div className="form-group mb-3">
                                <input type="text" className="form-control" placeholder="Enter Employee's Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
                            </div>
                            <div className="form-group mb-3">
                                <input type="date" className="form-control" value={date_reg} onChange={(e) => setDate_reg(e.target.value)} required />
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">Add Employee</button>
                        </form>
                    </div>
                ) : (
                    <div>
                        {/* <h3>You are not authenticated</h3> */}
                    </div>
                )}
            </div>
        </div>
    );
}
