import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface Employee {
    id: number;
    name: string;
    phone_number: string;
    IdNumber: string;
    role: string;
} 

export default function UpdateEmloyee() {
    const [name, setName] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [IdNumber, setIdNumber] = useState<string>('');
    const [phone_number, setPhoneNumber] = useState<string>('');

    
const { id } = useParams<{ id: string }>();

useEffect(() => {
    loadStudent();
}, [id]);

const loadStudent = async () => {
    try {
        const result = await axios.get<Employee>(`https://api.darululuum.com/employee/employees/${id}`);
        const { data } = result;
        setName(data.name);
        setRole(data.role);
        setIdNumber(data.IdNumber);
        setPhoneNumber(data.phone_number);
    } catch (error) {
        console.error('Error loading employee:', error);
    }
};

const updateSingleemployee = async () => {
    try {
        const formField = new FormData();
        formField.append('name', name);
        formField.append('role', role);
        formField.append('IdNumber', IdNumber);
        formField.append('phone_number', phone_number);

        const response = await axios.put(`https://api.darululuum.com/employee/employees/${id}/`, formField);
        alert('employee updated');
        window.location.href = '/employees';
    } catch (error) {
        console.error('Error updating employee:', error);
    }
};

  return (
    <div>
       <>
        <div className="container">
            <div className="w-75 mx-auto shadow p-5">
                <h2 className="text-center mb-4">Update A Teacher</h2>
               
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Course"
                        value={IdNumber}
                        onChange={(e) => setIdNumber(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter National ID"
                        value={phone_number}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>
                <button onClick={updateSingleemployee} className="btn btn-primary btn-block">Update employee</button>
            </div>
        </div>
        </>
    </div>
  )
}
