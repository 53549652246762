import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './Redux/store';
import { setAuth } from './Redux/authSlice';

const Sidebar: React.FC = () => {
    const [userRole, setUserRole] = useState<string | null>(null);
    const [showFeesOptions, setShowFeesOptions] = useState(false);
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth.value);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);

    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                const response = await axios.get('https://api.darululuum.com/api/user');
                setUserRole(response.data.role);
                dispatch(setAuth(true));
            } catch (error) {
                console.error('Error fetching user role:', error);
                dispatch(setAuth(false));
            }
        };

        fetchUserRole();

        const handleResize = () => {
            setIsSidebarVisible(window.innerWidth > 980);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch]);

    const toggleSidebarVisibility = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    return (
        <>
            {auth ? (
                <>
                    <button
                        className="btn btn-primary toggle-sidebar-btn d-lg-none"
                        onClick={toggleSidebarVisibility}
                        style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1000 }}
                    >
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className={`sidebar-container ${isSidebarVisible ? 'visible' : ''}`}>
                        <div className="sidebar">
                            <a href="/dashboard" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                                <img src="/images/darulUlum-removebg-preview.png" alt="Sidebar Logo" className="logoIcon" style={{ width: '80px', }} />
                            </a>
                            <hr className="separator" />
                            <ul className="nav nav-pills flex-column mb-auto">
                                <li>
                                    <Link to='/dashboard' className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-desktop me-2" style={{ color: 'white' }}></i>
                                        Dashboard
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/students" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-university me-2" style={{ color: 'white' }}></i>
                                        Students
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/add-students" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-building me-2" style={{ color: 'white' }}></i>
                                        Add Student
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/employees" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-users me-2" style={{ color: 'white' }}></i>
                                        Employees
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/add-employee" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-user-plus me-2" style={{ color: 'white' }}></i>
                                        Add employee
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/upload" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-upload me-2" style={{ color: 'white' }}></i>
                                        Upload students
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/ExpenseList" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-money-bill me-2" style={{ color: 'white' }}></i>
                                        Expense List
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/expenses" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-add me-2" style={{ color: 'white' }}></i>
                                        Add Expense
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/income" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-wallet me-2" style={{ color: 'white' }}></i>
                                        Income
                                    </Link>
                                </li>
                                    <>
                                        <li>
                                            <Link to="/register" className="nav-link" style={{ color: 'white' }}>
                                                <i className="fa-solid fa-plus me-2" style={{ color: 'white' }}></i>
                                                Register User
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/users" className="nav-link" style={{ color: 'white' }}>
                                                <i className="fa-solid fa-users me-2" style={{ color: 'white' }}></i>
                                                Users
                                            </Link>
                                        </li>
                                    </>
                                <li>
                                    <a href="/" className="nav-link" style={{ color: 'white' }} onClick={toggleFeesOptions}>
                                        <i className="fa-solid fa-money-bill me-2" style={{ color: 'white' }}></i>
                                        Fees
                                        <i className="fa-solid fa-circle-chevron-down ms-2" style={{ color: 'white' }}></i>
                                    </a>
                                </li>
                                {showFeesOptions && (
                                    <>
                                        <li>
                                            <Link to="/fees" className="nav-link subNavItem" style={{ color: 'white' }}>
                                                <i className="fa-solid fa-dollar-sign me-2" style={{ color: 'white' }}></i>
                                                View Fees
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/add-fees" className="nav-link subNavItem" style={{ color: 'white' }}>
                                                <i className="fa-solid fa-wallet me-2" style={{ color: 'white' }}></i>
                                                Add Fee
                                            </Link>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <Link to="/teachers" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-person-chalkboard me-2" style={{ color: 'white' }}></i>
                                        Teachers
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/add-teachers" className="nav-link" style={{ color: 'white' }}>
                                        <i className="fa-solid fa-person-chalkboard me-2" style={{ color: 'white' }}></i>
                                        Add Teacher
                                    </Link>
                                </li>
                            </ul>
                            <hr className="separator" />
                        </div>
                    </div>
                </>
            ) : (
                <div className="container mt-4">
                    {/* <p>You are not authenticated or authorized to view this page</p> */}
                </div>
            )}
        </>
    );

    function toggleFeesOptions(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        setShowFeesOptions(!showFeesOptions);
    }
};

export default Sidebar;
