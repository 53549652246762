import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from './Redux/authSlice';
import { RootState } from './Redux/store';
import Sidebar from './Sidebar';
import axios from 'axios';
import { Nav } from './Nav';
import { Link } from 'react-router-dom';
import Charts from './Charts';

const Dashboard = () => {
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', role: '' });
    const [numStudents, setNumStudents] = useState(0);
    const [numTeachers, setNumTeachers] = useState(0);
    const [numEmployees, setEmployees] = useState(0);
    const [feesCount, setFeesCount] = useState(0);
    const auth = useSelector((state: RootState) => state.auth.value);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [incomeData, setIncomeData] = useState<{ [key: string]: number }>({});
    const [totalIncome, setTotalIncome] = useState<string>('');
    const [totalExpenses, setTotalExpenses] = useState<string>('');
    const [netIncome, setNetIncome] = useState<string>('');
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.darululuum.com/fees/income/');
                setIncomeData(response.data.income_and_expenses_by_month);
                const currentMonthData = response.data.income_and_expenses_by_month[currentDateTime.toLocaleString('default', { month: 'long' })];
                if (currentMonthData) {
                    setTotalIncome(currentMonthData.total_income);
                    setTotalExpenses(currentMonthData.total_expenses);
                    setNetIncome(currentMonthData.net_income);
                }
            } catch (error) {
                console.error('Error fetching income data:', error);
            }
        };

        fetchData();
    }, [currentDateTime]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const { data } = await axios.get('https://api.darululuum.com/api/user');
                setUserInfo({ firstName: data.first_name, lastName: data.last_name, role: data.role });

                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        const fetchFeesCount = async () => {
            try {
                const response = await axios.get('https://api.darululuum.com/fees/fees-count/');
                setFeesCount(response.data.num_fees_paid);
            } catch (error) {
                console.error('Error fetching fees count:', error);
            }
        };

        const fetchStudentCount = async () => {
            try {
                const response = await axios.get('https://api.darululuum.com/students/students-count/');
                setNumStudents(response.data.num_students);
            } catch (error) {
                console.error('Error fetching student count:', error);
            }
        };

        const fetchTeacherCount = async () => {
            try {
                const response = await axios.get('https://api.darululuum.com/teachers/teachers-count/');
                setNumTeachers(response.data.num_teachers);
            } catch (error) {
                console.error('Error fetching teacher count:', error);
            }
        };
        const fetchEmployeeCount = async () => {
            try {
                const response = await axios.get('https://api.darululuum.com/employee/employee-count/');
                setEmployees(response.data.num_Employees);
            } catch (error) {
                console.error('Error fetching employee count:', error);
            }
        };

        fetchFeesCount();
        fetchStudentCount();
        fetchTeacherCount();
        fetchEmployeeCount();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const currentMonth = currentDateTime.toLocaleString('default', { month: 'long' });
    const currentDay = currentDateTime.getDate();

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    const sidebarStyle = {
        backgroundColor: '#3c8ac9',
        color: '#ffffff'
    };

    const cardStyle = {
        backgroundColor: '#3c8ac9',
        color: '#ffffff',
        border: '1px solid #ffff'
    };

    const buttonStyle = {
        backgroundColor: '#4e9b74',
        color: 'white'
    };
    return (
        <div className="container-fluid p-0">
            {loading ? (
                <p style={{color:"red", textAlign:"center"}}>Loading...</p>
            ) : (
                auth && ['admin', 'super_admin', 'accountant'].includes(userInfo.role) ? (
                    <div>
                        <div className="dashboard-container">
                            <div className="upper-Hero" style={{ backgroundColor: '#3c8ac9', color: '#ffff' }}>
                                <div className="left-hero">
                                    <h4 style={{ paddingLeft: "10px" }}>Darul Ulum Educational Centre Dashboard</h4>
                                </div>
                                <div className="right-hero">
                                    <button className='btn btn-primary' style={buttonStyle}>
                                        Today: {currentMonth} {currentDay}
                                    </button>
                                </div>
                            </div>
                            <div className="middle-hero">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="card text-center" style={cardStyle}>
                                            <div className="card-body">
                                                <i className="fa-solid fa-user-graduate" style={{ fontSize: '3rem', color: '#ffff' }}></i>
                                                <h2 className="students-count">{numStudents}</h2>
                                                <p className="card-text">Number of students</p>
                                                <button className="btn btn-outline-light"><Link to="/students" className="navLink" style={{color:"#0e0c0e", textDecoration:"none"}}>View</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card text-center" style={cardStyle}>
                                            <div className="card-body">
                                                <i className="fa-solid fa-chalkboard-teacher" style={{ fontSize: '3rem', color: '#ffff' }}></i>
                                                <h2 className="teachers-count">{numTeachers}</h2>
                                                <p className="card-text">Number of teachers</p>
                                                <button className="btn btn-outline-light"><Link to="/teachers" className="navLink" style={{color:"#0e0c0e", textDecoration:"none"}}>View</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card text-center" style={cardStyle}>
                                            <div className="card-body">
                                                <i className="fa-solid fa-chalkboard-teacher" style={{ fontSize: '3rem', color: '#ffff' }}></i>
                                                <h2 className="teachers-count">{numEmployees}</h2>
                                                <p className="card-text">Number of Other Employees</p>
                                                <button className="btn btn-outline-light"><Link to="/employees" className="navLink" style={{color:"#0e0c0e", textDecoration:"none"}}>View</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card text-center" style={cardStyle}>
                                            <div className="card-body">
                                                <i className="fa-solid fa-money-bill" style={{ fontSize: '3rem', color: '#ffff' }}></i>
                                                <h2 className="fees-count">{feesCount}</h2>
                                                <p className="card-text">Number of students paid</p>
                                                <button className="btn btn-outline-light"><Link to="/fees" className="navLink" style={{color:"#0e0c0e", textDecoration:"none"}}>View</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card text-center" style={cardStyle}>
                                            <div className="card-body">
                                                <i className="fa-solid fa-money-bill-trend-up" style={{ fontSize: '3rem', color: '#ffff' }}></i>
                                                <h5 className="card-text">Income: ksh {totalIncome}</h5>
                                                <button className="btn btn-outline-light"><Link to="/income" className="navLink" style={{color:"#0e0c0e", textDecoration:"none"}}>View</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card text-center" style={cardStyle}>
                                            <div className="card-body">
                                                <i className="fa-solid fa-money-bill-trend-up" style={{ fontSize: '3rem', color: '#ffff' }}></i>
                                                <h5 className="card-text">Expenses: ksh {totalExpenses}</h5>
                                                <button className="btn btn-outline-light"><Link to="/ExpenseList" className="navLink" style={{color:"#0e0c0e", textDecoration:"none"}}>View</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="card text-center" style={cardStyle}>
                                            <div className="card-body">
                                                <i className="fa-solid fa-money-bill-trend-up" style={{ fontSize: '3rem', color: '#ffff' }}></i>
                                                <h5 className="card-text">Net Income: ksh {netIncome}</h5>
                                                <button className="btn btn-outline-light"><Link to="/income" className="navLink" style={{color:"#0e0c0e", textDecoration:"none"}}>View</Link></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <Charts/>
                        </div>
                    </div>
                ) : (
                    <></>
                )
            )}
        </div>
    );
};

export default Dashboard;


