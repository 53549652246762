import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, ResponsiveContainer } from 'recharts';

interface MonthlyData {
    total_income: string;
    total_expenses: string;
    net_income: string;
}

interface ExpensesData {
    Expenses_type: string;
    Cost: string;
}

const Charts: React.FC = () => {
    const [incomeData, setIncomeData] = useState<{ month: string; total_income: number }[]>([]);
    const [expensesData, setExpensesData] = useState<ExpensesData[]>([]);

    useEffect(() => {
        const fetchIncomeData = async () => {
            try {
                const response = await axios.get<{ income_and_expenses_by_month: Record<string, MonthlyData> }>('https://api.darululuum.com/fees/income/');
                const transformedIncomeData = Object.entries(response.data.income_and_expenses_by_month).map(([month, data]) => ({
                    month,
                    total_income: parseFloat(data.total_income.replace(',', '')),
                }));
                setIncomeData(transformedIncomeData);
            } catch (error) {
                console.error('Error fetching income data:', error);
            }
        };

        const fetchExpensesData = async () => {
            try {
                const response = await axios.get<ExpensesData[]>('https://api.darululuum.com/fees/expenses/');
                setExpensesData(response.data);
            } catch (error) {
                console.error('Error fetching expenses data:', error);
            }
        };

        fetchIncomeData();
        fetchExpensesData();
    }, []);

    return (
        <div className="container mt-5 text-center">
            <div className="row">
                <div className="col-12 col-lg-6 mb-4">
                    <div className="chart">
                        <h4 style={{ color: '#333', marginBottom: '20px' }}>Income Trend</h4>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={incomeData}>
                                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                                <XAxis dataKey="month" tick={{ fill: '#555', fontSize: 12 }} />
                                <YAxis tick={{ fill: '#555', fontSize: 12 }} />
                                <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc' }} />
                                <Legend />
                                <Bar dataKey="total_income" fill="#3c8ac9" fillOpacity={0.7} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mb-4">
                    <div className="chart">
                        <h4 style={{ color: '#333', marginBottom: '20px' }}>Expenses Trend</h4>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={expensesData}>
                                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                                <XAxis dataKey="Expenses_type" tick={{ fill: '#555', fontSize: 12 }} />
                                <YAxis tick={{ fill: '#555', fontSize: 12 }} />
                                <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc' }} />
                                <Legend />
                                <Line type="monotone" dataKey="Cost" stroke="#3c8ac9" strokeWidth={2} dot={{ fill: '#8884d8', r: 6 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Charts;
