import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { setAuth } from '../Redux/authSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../Redux/store';
import { useDispatch } from 'react-redux';

interface Student {
    id: number;
    name: string;
    birth_certificate: string;
    date_of_registration: string;
    student_type: string;
    parent_phone_number: string;
    index_number: string;
    Class: string;
    section: string;
}

export default function DetailStudent() {
    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth.value);
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '' });
    const [userRole, setUserRole] = useState('');

    const [student, setStudent] = useState<Student>({} as Student);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Retrieve token from cookie
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                // Set token in Axios default headers
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                // Fetch user data
                const { data } = await axios.get('https://api.darululuum.com/api/user');
                setUserInfo({ firstName: data.first_name, lastName: data.last_name });
                setUserRole(data.role);

                // Set authentication status in Redux store
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    useEffect(() => {
        getSingleStudent();
    }, []);

    const getSingleStudent = async () => {
        try {
            const { data } = await axios.get<Student>(`https://api.darululuum.com/students/students/${id}/`);
            setStudent(data);
        } catch (error) {
            console.error('Error fetching student:', error);
        }
    };

    const handleDelete = async () => {
        if (userRole === 'admin') {
            if (window.confirm("Are you sure you want to delete this student?")) {
                try {
                    await axios.delete(`https://api.darululuum.com/students/students/${id}/`);
                    window.location.href = '/students';
                    alert(`You have deleted ${student.name}`);
                } catch (error) {
                    console.error('Error deleting student:', error);
                }
            }
        } else {
            alert("You do not have permission to delete this student.");
        }
    };

    // Function to get cookie by name
    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    return (
        <>
        <div className="container mt-5">
            {auth ? (
                <div>
                    {/* <Sidebar userName={`${userInfo.firstName} ${userInfo.lastName}`} /> */}
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="card" style={{backgroundColor:"white"}}>
                                <div className="card-body text-center">
                                    <h2 className="card-title">Student Details</h2>
                                    <hr />
                                    <p className="card-text"><strong>Name:</strong> {student.name}</p>
                                    <p className="card-text"><strong>Birth Certificate:</strong> {student.birth_certificate}</p>
                                    <p className="card-text"><strong>Date of Registration:</strong> {student.date_of_registration}</p>
                                    <p className="card-text"><strong>Student Type:</strong> {student.student_type}</p>
                                    <p className="card-text"><strong>Parent Phone Number:</strong> {student.parent_phone_number}</p>
                                    <p className="card-text"><strong>Index Number:</strong> {student.index_number}</p>
                                    <p className="card-text"><strong>Class Name:</strong> {student.Class}</p>
                                    <p className="card-text"><strong>Section Name:</strong> {student.section}</p>
                                    <div className="d-flex justify-content-center">
                                        <Link className="btn btn-outline-primary mr-2" to={`/${id}/update-student`}>Update</Link>
                                        {userRole === 'admin' && <button className="btn btn-danger" onClick={handleDelete}>Delete</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <h3>You are not authenticated</h3>
                </div>
            )}
        </div>
        </>
    );
}
