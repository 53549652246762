import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { setAuth } from '../Redux/authSlice';
import { RootState } from '../Redux/store';
import { useSelector, useDispatch } from 'react-redux';

const ExpenseList: React.FC = () => {
    const [expenses, setExpenses] = useState<any[]>([]);
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', role: '' });
    const auth = useSelector((state: RootState) => state.auth.value);

    useEffect(() => {
        const fetchExpenses = async () => {
            try {
                const response = await axios.get('https://api.darululuum.com/fees/expenses/');
                setExpenses(response.data);
            } catch (error) {
                console.error('Error fetching expenses:', error);
            }
        };

        fetchExpenses();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = getCookie('token');
                if (!token) {
                    dispatch(setAuth(false));
                    return;
                }

                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                const { data } = await axios.get('https://api.darululuum.com/api/user');
                setUserInfo({ firstName: data.first_name, lastName: data.last_name, role: data.role });
                dispatch(setAuth(true));
            } catch (e) {
                dispatch(setAuth(false));
            }
        };

        fetchData();
    }, [dispatch]);

    const getCookie = (name: string) => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    };

    return (
        <>
        <div className="container-fluid mt-4">
            {auth && userInfo.role === 'admin' || userInfo.role === 'super_admin'? (
                <div className="container" style={{ maxWidth: '770px' }}>
                    <h4 className="text-center">Expenses List</h4>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Expense Type</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Date Paid</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expenses.length === 0 ? (
                                    <tr>
                                        <td colSpan={4} className="text-center">No data available</td>
                                    </tr>
                                ) : (
                                    expenses.map((expense, index) => (
                                        <tr key={expense.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{expense.Expenses_type}</td>
                                            <td>ksh {expense.Cost}</td>
                                            <td>{expense.date_paid_expenses}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="container mt-4">
                    {/* <h3>You are not authenticated or authorized to view this page</h3> */}
                </div>
            )}
        </div>
        </>
    );
};

export default ExpenseList;
