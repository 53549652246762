import { Link } from "react-router-dom";
import { setAuth } from "./Redux/authSlice";
import axios from "axios";
import { RootState } from "./Redux/store";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import "../App.css";

export const Nav = () => {
    const auth = useSelector((state: RootState) => state.auth.value);
    const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', role: '' });
    const [togleside, setToggle] = useState(false);
    const dispatch = useDispatch();

    const logout = async () => {
        await axios.post('logout', {}, { withCredentials: true });
        deleteTokenCookie();
        dispatch(setAuth(false));
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axios.get('https://api.darululuum.com/api/user');
                setUserInfo({ firstName: response.data.first_name, lastName: response.data.last_name, role: response.data.role });
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };
        fetchUserInfo();
    }, []);

    const deleteTokenCookie = () => {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    };

    const handleDocumentClick = (e: MouseEvent) => {
        if (togleside && !(e.target as HTMLElement).closest('.side-wrapper') && !(e.target as HTMLElement).closest('.fa-bars')) {
            setToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [togleside]);

    let links;

    if (auth) {
        links = <div className="text-end">
            <Link to="/" onClick={logout} className="btn btn-outline-light me-2">Logout</Link>
        </div>
    } else {
        links = <div className="text-end">
            <Link to="/" className="btn btn-outline-light me-2">Login</Link>
        </div>
    }

    return (
        <header className="p-3 bg-dark text-white sticky-top">
            <div className="container-fluid"> {/* Changed to container-fluid */}
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div>
                        {togleside ?
                            <i className="fa-solid fa-xmark" onClick={() => setToggle(false)} style={{cursor:"pointer"}}></i>
                            :
                            <i className="fa-solid fa-bars" onClick={() => setToggle(true)} style={{cursor:"pointer"}}></i>
                        }
                    </div>
                    {links}
                </div>
            </div>
            {togleside && (
                <div className="side-wrapper">
                    <Sidebar />
                </div>
            )}
        </header>
    );
};
